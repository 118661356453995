import { getDisplayProperty, getOrderBy, getPascalCaseFieldName, getPicklistFieldExpression, PicklistDisplaySetting, PICKLIST_ITEM_SORT_ORDER_EXPRESSION, PicklistSetting, } from '@samc/picklist-api';
import { GetValueFromData, isNullOrUndefined, } from '@samc/react-ui-core';
import { GetFilterModelForTextFilter } from '@samc/react-ui-grid';
import { PicklistFieldExpressionCurrentRecordPrefix } from '../FilterExpressionField/FilterExpressionField';
export const insensitiveEqual = (a, b) => {
    return String(a).toLowerCase() === String(b).toLowerCase();
};
/**
 * Turns the selected ids in a picklist into either a concatenated string delimited by commas or a standalone string/number.
 * @param selectedIds The selected id or ids returned by a picklist in onChange
 */
export const GetJoinedReturn = (selectedIds) => {
    if (Array.isArray(selectedIds))
        return selectedIds.map(String).join(',');
    return selectedIds;
};
/**
 * Takes a flattened array value or constant from ls like "test1, test2, test3" and parses it into a list of selected ids.
 *
 * Does everything it can to convert numeric values
 * @example "test1, test2, test3" => ['test1', 'test2', 'test3']
 * @example undefined => undefined
 * @example "test1" => ['test1']
 * @example 2 => [2]
 * @example '2' => [2]
 * @example "2, 3, 4, 5" => [2, 3, 4, 5]
 */
export const FlattenedToArray = (input, picklistSetting) => {
    if (typeof input === 'number' || typeof input === 'boolean')
        return [input];
    if (typeof input === 'string') {
        if (input === '')
            return undefined;
        let ids;
        switch (picklistSetting) {
            case PicklistSetting.Single:
            case PicklistSetting.GridSelectInput:
                // Don't split on comma if we expect a single selection (which may have a comma in the id)
                ids = [input];
                break;
            case PicklistSetting.MultiSelect:
            case PicklistSetting.MultiGridSelectInput:
            default:
                ids = input.split(',');
                break;
        }
        return ids.map((i) => {
            const trimmed = i.trim();
            if (!Number.isNaN(Number(trimmed)))
                return Number(trimmed);
            if (trimmed.toLowerCase() === 'true' || trimmed.toLowerCase() === 'false')
                return trimmed.toLowerCase() === 'true';
            return trimmed;
        }); // try to convert number ids
    }
    return undefined;
};
export function HandleChange(valueProperty, selectedOption, currentSelectedIds, onChange) {
    const { data: picklistItem } = selectedOption;
    if (!valueProperty || !picklistItem)
        return;
    const id = picklistItem[valueProperty];
    if (id === undefined)
        return;
    const selectedIds = currentSelectedIds.filter((i) => !insensitiveEqual(i, id));
    if (selectedOption.selected)
        selectedIds.push(id);
    if (onChange)
        onChange(selectedIds);
}
export const HandleChangeGrid = (valueProperty, selectedOption, onChange) => {
    if (!valueProperty)
        return;
    const filter = (o) => !isNullOrUndefined(o);
    let selectedIds;
    if (selectedOption && selectedOption.length) {
        selectedIds = selectedOption.map((o) => o[valueProperty]).filter(filter);
    }
    else
        selectedIds = [];
    if (onChange) {
        onChange(selectedIds);
    }
};
export const InputsToArray = (inputs) => {
    if (!inputs)
        return [];
    if (typeof inputs === 'object')
        return inputs.map((i) => String(i));
    return [String(inputs)];
};
/**
 * Pulls longName/shortName into their custom attributes and vice versa
 */
export const populateCustomNames = (items) => {
    return items.map((item) => {
        var _a, _b, _c, _d;
        return (Object.assign(Object.assign({}, item), { shortName: (_a = item.shortName) !== null && _a !== void 0 ? _a : item.customShortName, longName: (_b = item.longName) !== null && _b !== void 0 ? _b : item.customLongName, customLongName: (_c = item.customLongName) !== null && _c !== void 0 ? _c : item.longName, customShortName: (_d = item.customShortName) !== null && _d !== void 0 ? _d : item.shortName }));
    });
};
/**
 * Gets the display value, does shifts longName/shortName into custom fields and vice versa (using populateCustomNames)
 */
export const getDisplayValue = (item, displayProperty, valueProperty) => {
    var _a;
    const displayText = (_a = item[displayProperty]) !== null && _a !== void 0 ? _a : populateCustomNames([item])[0][displayProperty];
    if (!displayText)
        return item[valueProperty];
    return displayText;
};
/**
 * Evaluates picklist display expressions and outputs picklist items with
 * a 'hidden' attribute using the result and the isActive flag
 *
 * @deprecated we no longer filter hidden results in-line
 */
export const FilterHiddenResults = (items, evaluator, data) => {
    return items.map((item) => {
        const { displayExpression, isActive } = item;
        const isVisible = isActive && (!displayExpression || !data || evaluator.evaluate(data, displayExpression) === true);
        return Object.assign(Object.assign({}, item), { hidden: !isVisible });
    });
};
/**
 * Partializes display expressions and applies `isActive`
 */
export const ManageDisplayExpressions = (items, evaluator, data) => {
    return items.map((item) => {
        var _a;
        const { displayExpression, isActive } = item;
        let newDisplayExpression = displayExpression;
        if (!isActive)
            newDisplayExpression = 'false';
        else if (data === undefined && ((_a = displayExpression === null || displayExpression === void 0 ? void 0 : displayExpression.indexOf('[')) !== null && _a !== void 0 ? _a : -1) >= 0)
            newDisplayExpression = 'true';
        else if (newDisplayExpression) {
            newDisplayExpression = evaluator.partialize(data, newDisplayExpression);
        }
        return Object.assign(Object.assign({}, item), { displayExpression: newDisplayExpression });
    });
};
export const ResultsToOptions = (items, valueProperty, displayProperty) => {
    if (!valueProperty || !displayProperty)
        return [];
    return items.map((item) => {
        const displayText = getDisplayValue(item, displayProperty, valueProperty);
        return {
            id: item[valueProperty],
            displayText: String(displayText !== null && displayText !== void 0 ? displayText : item[valueProperty]), // just in case
            data: item,
            hoverText: item.hidden ? 'Hidden Selection' : undefined,
            hidden: item.hidden,
            displayExpression: item.displayExpression, // hidden will take precedence
        };
    });
};
/**
 * Gets the pascal case expression for the given filter text in picklist
 */
export const getFilterExpressionForPicklist = (filterText, displayProperty) => {
    return `DoesContain('${filterText}', ${getPicklistFieldExpression(displayProperty)})`;
};
export const getFilterModelForPicklist = (filterModel, fields) => {
    const filterModalForText = Object.assign({}, filterModel);
    Object.keys(filterModel).forEach((key) => {
        const newKey = getPascalCaseFieldName(key);
        const model = filterModalForText[key];
        delete filterModalForText[key];
        filterModalForText[newKey] = model;
    });
    return GetFilterModelForTextFilter(filterModalForText, fields);
};
export const markGridSelection = (api, dropdownSelectedKeys) => {
    if (api && Object.keys(api).length && (dropdownSelectedKeys === null || dropdownSelectedKeys === void 0 ? void 0 : dropdownSelectedKeys.length)) {
        dropdownSelectedKeys.forEach((id) => {
            const node = api.getRowNode(id);
            node === null || node === void 0 ? void 0 : node.setSelected(true, false);
        });
    }
};
/**
 * Retrieves the sort order for a given picklist field
 */
export const getPicklistFieldSort = (picklistField) => {
    let sortByExpression;
    if (!picklistField)
        sortByExpression = PICKLIST_ITEM_SORT_ORDER_EXPRESSION;
    else {
        sortByExpression = getOrderBy(getDisplayProperty(picklistField.displaySetting || PicklistDisplaySetting.CustomShortName), picklistField.sortType, picklistField.customSortField ? getDisplayProperty(picklistField.customSortField) : undefined, picklistField.gridFieldConfiguration);
    }
    return {
        order: 'asc',
        orderBy: {
            scalarExpression: sortByExpression,
        },
    };
};
/**
 * A helper function to introduce fewer branches
 * @param value the value to pass into the callback, should it be defined
 * @param cb the callback
 * @param fallback a fallback, if the value is undefined
 * @returns the output of the callback if defined, or fallback otherwise
 */
export const withNullable = (value, cb, fallback) => {
    if (!value)
        return fallback;
    return cb(value);
};
/**
 * Verifies that a row is selectable, for use primarily in grids
 * @deprecated no longer in use
 */
export const isRowSelectable = ({ data }) => {
    if (!data)
        return false;
    const { hidden, isActive } = data;
    return !hidden && isActive;
};
export const processFilterExpression = (filterExpression, currentRecord) => {
    if (!currentRecord)
        return filterExpression;
    return filterExpression.replace(new RegExp(`\\[${PicklistFieldExpressionCurrentRecordPrefix}([^\\]]+)\\]`, 'g'), (_, field) => {
        var _a;
        const value = (_a = GetValueFromData(currentRecord, field)) !== null && _a !== void 0 ? _a : GetValueFromData(currentRecord, field.replace(/^./, (vv) => vv.toLowerCase()));
        if (typeof value === 'string')
            return `'${value}'`;
        if (isNullOrUndefined(value))
            return 'null';
        return String(value);
    });
};
/**
 * Takes an input picklist ref and creates the callback for useImperativeHandle to forward it up
 */
export const copyImperativeHandle = (ref) => ({
    get popoutElement() {
        return withNullable(ref.current, (dr) => dr.popoutElement, null);
    },
    reload: () => withNullable(ref.current, (dr) => dr.reload(), undefined),
    focusInput: (v) => withNullable(ref.current, (dr) => dr.focusInput(v), undefined),
    getSelectedItems: () => withNullable(ref.current, (dr) => dr.getSelectedItems(), []),
});
/** *
 * Takes an input and remove the optional displayValue to
 * @example 'asset(asset 123) => asset
 */
export const removeOptionalDisplayValue = (value) => {
    if (typeof value === 'string') {
        const stack = new Array(2);
        const stackIndex = [];
        const splitValue = value.trim().split('');
        for (let index = splitValue.length - 1; index > 0; index--) {
            if (splitValue[index] === ')' && !stack[1]) {
                stack[1] = index;
                stackIndex.push(index);
            }
            else if (splitValue[index] === ')' && stack[1]) {
                stackIndex.push(index);
            }
            if (splitValue[index] === '(' && stackIndex.length === 1) {
                stack[0] = index;
                stackIndex.push(index);
            }
            else if (splitValue[index] === '(' && stackIndex.length > 1) {
                stackIndex.shift();
            }
        }
        if (!stack[0] && !stack[1])
            return value;
        return splitValue.splice(0, stack[0]).join('').trim();
    }
    return value;
};
