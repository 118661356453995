import { PicklistDisplaySetting } from '@samc/picklist-api';
import { FlattenedToArray, GetJoinedReturn, removeOptionalDisplayValue, } from '../../../atoms/controls/Picklist/PicklistFunctions';
import { getPicklistField } from '../../types/PicklistFieldGetter';
export const PicklistFieldValidator = (picklistFieldGetter, getGridPicklist, evaluator, fieldName) => (params) => {
    const { value, data, node } = params;
    const picklistField = getPicklistField(picklistFieldGetter, data);
    const gridPicklist = getGridPicklist(picklistField);
    if (!gridPicklist)
        return { isValid: false };
    const { displaySetting } = picklistField;
    const selectedIds = FlattenedToArray(value, picklistField.setting) || [];
    const targetPicklistItems = [];
    const missingValues = new Set();
    const hiddenItems = [];
    // -------------------------- check id values --------------------------------
    selectedIds.forEach((idOrDisplayText) => {
        let resolvedItem = gridPicklist.getCachedItem(idOrDisplayText, [PicklistDisplaySetting.Id, displaySetting]); // check id or display setting
        if (!resolvedItem) {
            const valueWithoutOptionalDisplay = removeOptionalDisplayValue(idOrDisplayText);
            resolvedItem = gridPicklist.getCachedItem(valueWithoutOptionalDisplay, [
                PicklistDisplaySetting.Id,
                displaySetting,
            ]); // check id or display setting
            if (!resolvedItem)
                missingValues.add(valueWithoutOptionalDisplay);
        }
        if (!resolvedItem) {
            missingValues.add(idOrDisplayText);
        }
        else if (!resolvedItem.displayExpression ||
            evaluator.evaluate(data, resolvedItem.displayExpression) === true)
            targetPicklistItems.push(resolvedItem);
        else
            hiddenItems.push(resolvedItem);
    });
    if (targetPicklistItems.length === selectedIds.length)
        return {
            newValue: GetJoinedReturn(targetPicklistItems.map((i) => i.id)),
            isValid: true,
        };
    if (hiddenItems.length > 0)
        return { isValid: false };
    if (missingValues.size === 0)
        return {
            newValue: GetJoinedReturn(targetPicklistItems.map((i) => i.id)),
            isValid: true,
        };
    if (node) {
        gridPicklist.setValueSetterPending(node, true);
        gridPicklist
            .resolveItems(Array.from(missingValues), [PicklistDisplaySetting.Id, displaySetting])
            .then((items) => {
            if (items.length !== missingValues.size)
                return;
            node.setDataValue(fieldName, GetJoinedReturn(targetPicklistItems.concat(items).map((i) => i.id)));
        })
            .finally(() => gridPicklist.setValueSetterPending(node, false));
    }
    return { isValid: false };
};
export default PicklistFieldValidator;
