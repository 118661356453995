/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
/* eslint-disable eqeqeq */
import * as React from 'react';
import { getDisplayProperty } from '@samc/picklist-api';
import { LinkRenderer } from '@samc/react-ui-grid';
import { Shimmer } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { useEvaluator } from '@samc/expressions-react';
import { GetValueFromData } from '@samc/react-ui-core';
import { FilterHiddenResults, FlattenedToArray, } from '../../../atoms/controls/Picklist/PicklistFunctions';
import { getGridDisplayValue } from './Utils';
import { PicklistToggle } from '../../../atoms/controls/PicklistToggle/PicklistToggle';
import { getPicklistField } from '../../types/PicklistFieldGetter';
import { PartialExpressionEvaluator } from '../../../utilities/PartialExpressionEvaluator/PartialExpressionEvaluator';
/**
 * Indicates that an async operation is ongoing related to the population of the picklist value.
 * Ex: a paste operation or rule-driven value change
 * When the value is set to this, the grid will show as loading
 */
export const PICKLIST_LOADING_VALUE = '__PICKLIST_LOADING__';
export const PicklistCellRenderer = (props) => {
    var _a;
    const { customRenderer, data, getPicklist, inEditMode, link, node, picklistField: picklistFieldGetter, setValue: setValueProp, field, column, } = props;
    const { evaluate } = useEvaluator();
    const isEditable = column && column.isCellEditable(node);
    const [displayValue, setDisplayValue] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [css] = useStyletron();
    const value = ((_a = GetValueFromData(data, field)) !== null && _a !== void 0 ? _a : '');
    const partialEvaluator = React.useMemo(() => new PartialExpressionEvaluator(evaluate), [evaluate]);
    const picklistField = React.useMemo(() => getPicklistField(picklistFieldGetter, data, node), [data, picklistFieldGetter, node]);
    const gridPicklist = React.useMemo(() => getPicklist(picklistField), [getPicklist, picklistField]);
    const [isValueSetterPending, setValueSetterPending] = React.useState(gridPicklist && gridPicklist.getValueSetterPending(node));
    const { renderAsToggle, displaySetting } = picklistField;
    const selectedIds = React.useMemo(() => {
        return FlattenedToArray(value, picklistField.setting);
    }, [picklistField.setting, value]);
    /**
     * Processes hidden items and tracks them
     */
    const processHiddenItems = React.useCallback((items) => FilterHiddenResults(items, partialEvaluator, data), [data, partialEvaluator]);
    const fetchItems = React.useCallback((params) => {
        if (!gridPicklist)
            throw new Error('Grid picklist not initialized');
        return gridPicklist.fetchItems(params).then(({ items, totalCount }) => ({
            items: processHiddenItems(items),
            totalCount,
        }));
    }, [gridPicklist, processHiddenItems]);
    React.useEffect(() => {
        if (!gridPicklist)
            return;
        if (!selectedIds)
            setDisplayValue(undefined);
        else if (selectedIds.some((id) => !gridPicklist.getCachedItem(id))) {
            setIsLoading(true);
            gridPicklist.resolveItems(selectedIds).finally(() => {
                setIsLoading(false);
                setDisplayValue(getGridDisplayValue(picklistField, selectedIds, (id) => gridPicklist.getCachedItem(id)));
            });
        }
        else {
            setDisplayValue(getGridDisplayValue(picklistField, selectedIds, (id) => gridPicklist.getCachedItem(id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds]);
    React.useEffect(() => {
        if (!gridPicklist)
            return undefined;
        const listener = ({ isPending }) => {
            setValueSetterPending(isPending);
        };
        gridPicklist.addValueSetterListener(node, listener);
        return () => {
            gridPicklist.removeValueSetterListener(node, listener);
        };
    }, [gridPicklist, node]);
    if (isLoading || isValueSetterPending)
        return (React.createElement("div", { className: css({
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }) },
            React.createElement(Shimmer, { styles: { root: { height: '75%', width: '100%' }, shimmerWrapper: { height: '100%' } } })));
    if (renderAsToggle)
        return (React.createElement("div", { className: css({
                height: '100%',
                maxHeight: '29px', // to play nice in wrapping contexts
                padding: '4px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
            }) },
            React.createElement(PicklistToggle, { selectedKey: value, fetchItems: fetchItems, displayProperty: getDisplayProperty(displaySetting), valueProperty: "id", className: css({ height: '100% !important', minHeight: '16px', width: '100%' }), onChange: setValueProp, disabled: !isEditable || !inEditMode, suppressItemTooltips: true })));
    if (link)
        return React.createElement(LinkRenderer, { link: link, linkProps: props, value: displayValue, editable: inEditMode }); // for links, they only care about edit mode
    if (customRenderer)
        return customRenderer(props, displayValue);
    return React.createElement(React.Fragment, null, displayValue);
};
export default PicklistCellRenderer;
